<template>
  <div class="classHour" v-loading="loading">

    <!--    删除弹框-->
    <!--    <el-dialog
            class="delDiolog"

            :visible.sync="isdelDialogVisible"
            top="calc( 50vh - 150px )"
            icon="el-icon-warning"
            :destroy-on-close="true"
            width="360px"
            center>
          <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
          <span>你确定要删除该标签么？</span>
          <span slot="footer" class="dialog-footer form-operation">
            <el-button class="form-operation-item" round @click="isdelDialogVisible = false">取 消</el-button>
            <el-button class="form-operation-item" round type="primary" @click="isdelDialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>-->
    <!--  标签页-->
    <el-tabs v-model="activeName" class="el-nav" @tab-click="handleClick">
      <div class="el-nav-header flex-nowrap">
        <img :src="courseInfo.pc_cover_picture" class="table-img" alt="">
        <div class="header-r">
          <div class="table-item-name">{{ courseInfo.title }}</div>
          <div class="table-item-times flex-nowrap">
            <div class="table-item-time" v-if="!courseInfo.is_share">学习人数：{{ courseInfo.buy_num + courseInfo.learn_num > 9999 ? ((courseInfo.buy_num+ courseInfo.learn_num) / 10000 ).toFixed(2) + 'W': (courseInfo.buy_num+ courseInfo.learn_num) }}人学习<span style="color: #D0D6DE;margin: 0 8px">|</span></div>
            <div class="table-item-time">课程时长：{{ courseInfo.length ? $carbon.formatSeconds(courseInfo.length) : 0 }}</div>
          </div>
          <div>
            <el-tag
              style="margin-right: 20px;background:rgba(255, 165, 9, 0.1);border: unset;color: #FFA509;border-radius: 10px;padding: 0 10px;"
              v-for="(item,index) in courseInfo.tags"
              :key="index"
              size="mini"
              type="success"
              effect="dark">
              {{ item.name }}
            </el-tag>
          </div>
        </div>
      </div>
      <el-tab-pane label="课时管理" class="first" name="chapters">
        <!--        搜索查询-->
        <div class="search space-between">
          <div class="flex-nowrap">
            <el-select v-model="options" placeholder="标题" class="search-type">
              <el-option
                  v-for="item in optionsData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>

            <el-input v-model="keywords" placeholder="请输入内容" class="search-input"></el-input>


            <el-button type="primary" class="search-btn" @click="searchSubmit">查询</el-button>
            <el-button class="search-btn reset" @click="resetFun">重置</el-button>
          </div>
          <div>
            <el-button class="search-btn reset" :class="{'theme-btn-bor-color':chapters_show_status === 'show'}"  @click="showChapters" v-if="chaptersInfo.length">展开</el-button>
            <el-button class="search-btn reset" :class="{'theme-btn-bor-color':chapters_show_status === 'hide'}"  @click="hideChapters" v-if="chaptersInfo.length" style="margin-right: 30px">收起</el-button>
            <el-button type="warning" class="f-r search-add" @click="addChapters()" v-if="!courseInfo.is_share">添加章节</el-button>
          </div>
        </div>

        <div v-if="chaptersInfo.length">
          <el-tree
            class="collapse"
            :data="chaptersInfo"
            node-key="id"
            :default-expand-all="default_expand_all"
            :draggable="!courseInfo.is_share"
            ref="resourceTree"
            :props="defaultProps"
            :allow-drop="allowDrop"
        >
            <div class="custom-tree-node space-between" slot-scope="{ node, data }">
                <div class="collapse-item-left" :class="data.status == 1 ?'collapse-item-left-l' : 'collapse-item-left-g'"><span class="lesson-status" :class="data.status == 1 ? 'lesson-status-suc' : 'lesson-status-info'" v-if="data.type === 'lesson'">{{data.status == 1 ? '已发布' : '待发布'}}</span>{{data.number_path}} {{ data.title }}</div>
                <div class="collapse-item-right" v-if="!courseInfo.is_share">
                  <span class="collapse-item-right-span">
                    <img src="~assets/images/index/edit.png" class="editimg" alt=""  @click.stop="editChapters(data, data.type === 'chapter' ? 'chapter' : 'lesson')">
                    <img src="~assets/images/index/edit-h.png" class="editimgh" alt=""  @click.stop="editChapters(data, data.type === 'chapter' ? 'chapter' : 'lesson')">
                  </span>

                  <el-dropdown @command="chaptersHandle" style="margin-left: 20px;" >
                    <span class="el-dropdown-link">
                      <img src="~assets/images/index/mores.png" class="moresimg">
                      <img src="~assets/images/index/mores-h.png"  class="moresimgh">
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="['add', data]" v-if="data.type === 'chapter'">添加</el-dropdown-item>
                      <el-dropdown-item :command="['del', data]" v-if="data.type === 'chapter'">删除</el-dropdown-item>
                      <el-dropdown-item :command="['del', data]" v-if="data.type === 'lesson' && data.status != 1">删除</el-dropdown-item>
                      <el-dropdown-item :command="['status', data]" v-if="data.type === 'lesson'">{{data.status == 1 ? '关闭' : '发布'}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
            </div>
        </el-tree>
        </div>
        <empty-prompt v-else></empty-prompt>

        <div class="set-btn-box align-center form-operation" v-if="chaptersInfo.length && !courseInfo.is_share">
          <el-button  class="form-operation-item"   size="medium" round @click="cancelBtn">取消</el-button>
          <el-button class="form-operation-item"  size="medium" type="primary" round  @click="submitChapters">保存</el-button>
        </div>
      </el-tab-pane>

      <el-tab-pane label="课程媒体" class="two" name="media">
      </el-tab-pane>
      <el-tab-pane label="资料管理" class="three" name="materials">
      </el-tab-pane>
      <el-tab-pane :label="courseInfo.comment_count ? (courseInfo.comment_count > 99 ?  '留言 '+'99+' : '留言 '+courseInfo.comment_count) : '留言 '+ 0 " class="four" name="interact" v-if="!courseInfo.is_share">
      </el-tab-pane>
      <el-tab-pane label="学员管理" class="five" name="student" v-if="!courseInfo.is_share">
      </el-tab-pane>
    </el-tabs>

    <!--新增章节/课时-->
    <el-dialog
        class="dialog"
        :title="createdType === 'chapter' ? (isAdd ?'添加章/单元':'编辑章/单元' ) : (isAdd ?'新增课时':'编辑课时' )"
        :visible.sync="isDialogChapters"
        top="calc( 50vh - 150px )"
        width="540px"
        v-if="isDialogChapters"
        :destroy-on-close="true"
        :before-close="handleClose">
      <!--      表单-->
      <el-form label-position="right" label-width="100px" :model="chaptersForm" :rules="chaptersRules" ref="chaptersForm"
               class="form">
        <el-form-item :label="createdType === 'chapter' ? '章标题' : '课时名称'" prop="title">
          <el-input :placeholder="createdType === 'chapter' ? '请输入章标题名称' : '请输入课时名称' " maxlength="50"
                    v-model="chaptersForm.title"></el-input>
        </el-form-item>
        <el-form-item label="上传封面图" prop="preview" v-if="createdType === 'lesson'">
          <upload-file @successUrl="imgUrl" :limit="1" :folder="'course/images'" :default_value="previewArr"></upload-file>
        </el-form-item>
        <el-form-item label="视频" prop="media_id" v-if="createdType === 'lesson'">
          <el-select v-model="chaptersForm.media_id" placeholder="请选择视频" style="width: 100%;">
            <el-option
                v-for="(item, index) in mediaData"
                :key="index +1 +'.'+ item.name"
                :label="index+1+'.'+item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金币规则"  v-if="createdType === 'lesson'">
          <el-select v-model="chaptersForm.rule_item_id" placeholder="请选择" clearable  style="width: 100%;">
            <el-option
              v-for="item in ruleoptions"
              :key="item.id"
              :label="item.title +'('+ item.details.val +  '金币' + ')' "
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="描述" prop="desc">
          <el-input placeholder="请输入描述" maxlength="150" v-model="chaptersForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="closeChapters">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="chaptersCreate">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import uploadFile from "components/uploadFile";
import EmptyPrompt from "components/EmptyPrompt";

export default {
  name: "Chapters",
  data() {
    return {
      activeName: 'chapters', // 默认选中当前
      uuid: '',
      courseInfo: {}, // 获取课程信息
      chaptersInfo: [], // 章节数据
      isDialogChapters: false,  // 新增章节
      isDialogLesson: true, // 新增课时
      isAdd:false,
      page: 1,
      pageSize: 200,
      options: 'chapter_title',  // 选项
      optionsData: [
        {
          label: '章节标题',
          value: 'chapter_title'
        },
        {
          label: '课时标题',
          value: 'lesson_title'
        }
      ],
      keywords: '', // 关键词
      loading: true,
      createdType: '',
      // 新增章节
      chaptersForm: {
        title: '',
        desc: '',
        media_id: '',
        preview: '',
        rule_item_id:'',
      },
      mediaData: [],  // 选择视频
      chaptersRules: {},
      current_chapter: null,
      defaultProps: {
        children: 'lessons',
        label: 'title'
      },
      setType: 'create',  // 判断保存还是编辑
      default_expanded_arr: [],
      default_expand_all: true,
      rule_item_id:[],
      previewArr: [],
      chapters_show_status: 'show'
    }
  },
  created() {
    if (this.$route.params.uuid) {
      this.uuid = this.$route.params.uuid
      this.getCourses()
      this.getChapters()
      this.getMedia()
      this.getRule()
    }
  },
  methods: {
    //获取规则
    getRule(){
      let url= this.$api.ruleItems + `?code=lesson`;
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.ruleoptions = res.data.data
        }
      })
    },
    // 获取课程信息
    getCourses() {
      let url = this.$api.Courses + '/' + this.uuid;
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.courseInfo = res.data.data;
          this.loading = false
        }
      }).catch(err =>{
        this.loading = false
      })
    },
    // 获取章节
    getChapters() {
      let url = this.$api.Courses + '/' + this.uuid + '/' + this.$api.Lessons + `?page=${this.page}&pageSize=${this.pageSize}&options=${this.options}&keywords=${this.keywords}`;
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          setTimeout(() => {
            this.loading = false
          }, 300)
          this.chaptersInfo = res.data.data;
        }
      })
    },
    // 获取课程媒体
    getMedia() {
      let url= this.$api.Medias + `?is_paginate=0&type=course&model_uuid=${this.uuid}`;
      this.$http.get(url, true).then(res => {
        if(res.data.success) {
          this.mediaData= res.data.data
        }
      })
    },
    // 新增章节
    addChapters() {
      this.createdType = 'chapter'
      this.isAdd = true
      this.chaptersRules = {
        title: [
          {required: true, message: '请输入章标题', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ]
      }
      this.setType= 'create'
      this.previewArr=[]
      this.isDialogChapters = true
    },
    // 保存拖拽章节
    submitChapters() {
      let url= this.$api.Courses + '/' + this.uuid + '/' + this.$api.lessonsDrag;
      this.$http.post(url, {list: JSON.stringify(this.chaptersInfo)}, true).then(res =>{
        if(res.data.success) {
          this.getChapters()
          this.$message.success('保存成功')
        }else {
          this.$message.error(res.data.message)
        }
      }).catch(error =>{
        this.$message.error(error.response.data.message)
      })
    },
    // 取消
    cancelBtn() {
      this.$router.push('/courses/courses')
    },
    handleClose() {
      this.isDialogChapters = false
      this.isDialogLesson = false
    },
    // 搜索
    searchSubmit: _.debounce(function () {
      this.loading = true
      this.getChapters()
    }, 600),
    // 重置
    resetFun() {
      this.options = ''
      this.keywords = ''
      this.searchSubmit()
    },
    // 取消
    closeChapters() {
      this.chaptersForm.title = ''
      this.chaptersForm.desc = ''
      this.isDialogChapters = false
    },
    // 确定提交章标题
    chaptersCreate: _.debounce(function () {
      this.$refs.chaptersForm.validate((valid) => {
        if (valid) {
          this.chaptersCreateFun()
        } else {
          return false;
        }
      });

    }, 500),

    // 章节/课时创建方法
    chaptersCreateFun() {
      let url = this.$api.Courses + '/' + this.uuid + '/' + this.$api.Lessons;
      let data = {
        type: this.createdType,
        title: this.chaptersForm.title,
        desc: this.chaptersForm.desc
      }
      if (this.createdType === 'lesson') {
        data.chapter_id = this.current_chapter.id;
        data.preview = this.chaptersForm.preview;
        data.media_id = this.chaptersForm.media_id;
        data.rule_item_id = this.chaptersForm.rule_item_id
      }
      if(this.setType== 'edit') {
        url= this.$api.Courses + '/' + this.uuid + '/' + this.$api.Lessons + '/' + this.current_chapter.id;
        this.$http.put(url, data, true).then(res => {
          if (res.data.success) {
            this.closeChapters()
            this.getChapters()
            this.$message.success('创建成功')
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(error => {
          this.$message.error(error.response.data.message)
        })
      }else {
        this.$http.post(url, data, true).then(res => {
          if (res.data.success) {
            this.closeChapters()
            this.getChapters()
            this.$message.success('创建成功')
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(error => {
          this.$message.error(error.response.data.message)
        })
      }
    },
    // 编辑
    editChapters(data, type) {
      this.setType= 'edit'
      this.isAdd = false
      this.current_chapter= data
      this.createdType= type
      this.chaptersForm.title = data.title
      this.chaptersForm.desc = data.desc
      this.previewArr= []
      this.isDialogChapters = true
      if(type === 'lesson') {
        this.chaptersForm.media_id = data.media.id
        this.chaptersForm.preview = data.preview
        this.chaptersForm.rule_item_id = data.rule_item_id
        this.previewArr.push(data.preview)
      }
    },
    // 删除章节方法
    chaptersDelFun() {
      let url = this.$api.Courses + '/' + this.uuid + '/' + this.$api.Lessons + '/' + this.current_chapter.id;
      this.$http.del(url, true).then(res =>{
        if(res.data.success) {
          this.getChapters()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }else {
          this.$message.error(res.data.message)
        }
      }).catch(error=>{
        this.$message.error(error.response.data.message)
      })
    },
    // 章节标签选择
    chaptersHandle(command) {
      this.setType= 'create'
      this.createdType = 'lesson'
      this.current_chapter = command[1]
      if (command[0] === 'add') {
        this.chaptersForm.title= '';
        this.chaptersForm.desc= '';
        this.chaptersForm.media_id= '';
        this.chaptersForm.preview= '';
        this.isAdd = true
        this.chaptersRules = {
          title: [
            {required: true, message: '请输入课时名称', trigger: 'blur'},
            {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
          ],
          media_id: [
            {required: true, message: '请选择视频', trigger: 'blur'}
          ]
        }
        this.previewArr= []
        this.isDialogChapters = true
      }else if(command[0] === 'del') {
        this.$confirm('这是您操作比较重要的数据,你确定要删除该课时么？ 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.chaptersDelFun()
        }).catch(() => {});
      }else if(command[0] === 'status') {
        this.$confirm('这是您操作比较重要的数据,你确定要修改该课时么？ 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let url = this.$api.Courses + '/' + this.uuid + '/' + this.$api.Lessons + '/' + command[1].id + this.$api.changeStatus;
          this.$http.get(url, true).then(res =>{
            if(res.data.success) {
              this.$message.success('修改成功')
              this.getChapters()
            }else {
              this.$message.error(res.data.message)
            }
          }).catch(error => {
            this.$message.error(error.response.data.message)
          })
        }).catch(() => {});
      }
    },
    // 展开章节
    showChapters() {
      this.chapters_show_status= 'show'
      let nodesMap = this.$refs.resourceTree.store.nodesMap;
      for (let key in nodesMap) {
        // 全部关闭
        nodesMap[key].expanded = true;
        nodesMap[key].isCurrent = true;
      }
    },
    // 收起章节
    hideChapters() {
      this.chapters_show_status= 'hide'
      let nodesMap = this.$refs.resourceTree.store.nodesMap;
      for (let key in nodesMap) {
        // 全部关闭
        nodesMap[key].expanded = false;
        nodesMap[key].isCurrent = false;
      }
    },
    // 图片上传的
    imgUrl(data) {
      if(!data[data.length - 1]) {
        data[data.length - 1]= ''
      }
      this.chaptersForm.preview = data[data.length - 1]
      this.$refs.chaptersForm.clearValidate('preview')
    },
    // 判断是否能拖拽
    allowDrop(draggingNode, dropNode, type) {
      if(draggingNode.parent === dropNode.parent ) {
        if(type === 'prev' || type === 'next') {
          return true
        }else {
          return false
        }
      }else {
        return false
      }
    },
    // tab切换想像
    handleClick(tab, event) {
      if(tab.name === 'chapters') {
        this.$router.push({path: `/courses/class/${this.uuid}/chapters`})
      }else if(tab.name === 'media') {
        this.$router.push({path: `/courses/class/${this.uuid}/media`})
      }else if(tab.name === 'materials') {
        this.$router.push({path: `/courses/class/${this.uuid}/materials`})
      }else if(tab.name === 'interact') {
        this.$router.push({path: `/courses/class/${this.uuid}/interact`})
      }else if(tab.name === 'student') {
        this.$router.push({path: `/courses/class/${this.uuid}/student`})
      }else {
        this.$router.push({path: `/courses/courses`})
      }
    }
  },
  components: {
    uploadFile,
    EmptyPrompt
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/courses/classHour/index.less";
.custom-tree-node{
  width: 100%;
}
.set-btn-box{
  margin-top: 100px;
}
.lesson-status{
  font-size: 14px;
  margin-right: 6px;
}
.lesson-status-suc{
  color: #81D8D0;
}
.lesson-status-info{
  color: #909399;
}
.el-tab-pane{
  min-height: 528px;
}
.reset:hover{
  background: none!important;
}
.reset:focus{
  background: none;
}
</style>
